import type { AssetFragment } from '#gql'

export default function useAppImage(
  asset: AssetFragment | Array<AssetFragment | null> | null | undefined,
  sizes: string | undefined
) {
  const generatedSizes = computed(() => generateSizes(sizes) || '100vw')

  const image = computed(() => {
    if (Array.isArray(asset)) return asset[0]
    return asset
  })

  const hasSizesError = ref(false)

  const path = computed(() => image.value?.permalink?.split('.com')?.[1])

  const generateSizes = (inputStr: string | undefined) => {
    if (!inputStr || hasSizesError.value) {
      return 'xs:100vw sm:100vw md:100vw lg:100vw xl:100vw 2xl:100vw 3xl:100vw'
    }
    const screens = ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl']
    const inputPairs = inputStr.split(' ').map((pair) => pair.trim())

    const sizeMap: { [key: string]: string } = {}
    let recentSize: string | null = null

    // Loop through the input pairs and map them to their screens
    inputPairs.forEach((pair) => {
      if (pair.includes(':')) {
        const [screen, size] = pair.split(':')
        sizeMap[screen] = size
        recentSize = size // Update recent size
      } else {
        console.error(
          `Invalid format: "${pair}". Expected format is "screen:size" (e.g., "md:50vw").`
        )
        hasSizesError.value = true
      }
    })

    screens.forEach((screen) => {
      if (!sizeMap[screen] && recentSize) {
        sizeMap[screen] = recentSize
      } else if (sizeMap[screen]) {
        recentSize = sizeMap[screen] // Keep track of the most recent valid size
      }
    })

    const outputList = screens.map((screen) => `${screen}:${sizeMap[screen]}`)

    return outputList.join(' ')
  }

  return {
    sizes: generatedSizes,
    path,
  }
}
