<template>
  <NuxtImg
    v-if="image"
    provider="cms"
    :src="path"
    :alt="image.alt || ''"
    :sizes="sizes"
    :class="{
      'object-contain ': contain,
      'bg-black object-cover': !contain,
    }"
    :style="{
      'object-position': image?.focus_css || 'center',
    }"
    loading="lazy"
    placeholder
  />
</template>

<script setup lang="ts">
import type { AssetFragment } from '#gql'

const props = defineProps<{
  asset: AssetFragment | null | Array<AssetFragment | null> | undefined
  contain?: boolean
  sizes?: string
}>()

const image = computed(() => {
  if (Array.isArray(props.asset)) return props.asset[0]
  return props.asset
})

const { path, sizes } = useAppImage(props.asset, props.sizes)
</script>
